// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-js": () => import("./../src/pages/information.js" /* webpackChunkName: "component---src-pages-information-js" */),
  "component---src-pages-profile-js": () => import("./../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-category-page-js": () => import("./../src/templates/category/page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-photo-page-js": () => import("./../src/templates/photo/page.js" /* webpackChunkName: "component---src-templates-photo-page-js" */)
}

