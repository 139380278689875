/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"

import posed, { PoseGroup } from "react-pose"

const Transition = posed.div({})

export const replaceComponentRenderer = ({ props, ...other }) => {
  const { component } = props.pageResources
  return (
    <PoseGroup>
      <Transition key={props.location.pathname}>
        {React.createElement(component, props)}
      </Transition>
    </PoseGroup>
  )
}


const transitionDelay = 480

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}
